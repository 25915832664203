import React,  {useEffect, useState } from "react";
import  Layout  from "../components/Layout";
import styled, {css} from "styled-components"
import tw from "tailwind.macro"
import PublitioAPI from "publitio_js_sdk";
import { motion, useCycle } from "framer-motion";
import { FaFolder, FaFileVideo } from "react-icons/fa"
import { MdClose } from "react-icons/md";


const LessonsContainer = styled(motion.div)`
    ${tw`p-4 sm:pt-4 pt-8 w-full h-px overflow-y-scroll overflow-x-hidden sm:min-h-full  h-64`};
    background-color: ${props => props.theme.colors.deepBackground};
    border-radius: 20px;
    border-color:  ${props => props.theme.colors.gray};
    border-width: 3px;
`

const Container = styled(motion.div)`
  ${tw`my-8 py-8`};
`



const FolderIcon = styled(motion.button)`
        ${tw`px-4 py-4 mb-2 rounded-full lg:hidden right-0 absolute z-10`};
        background-image: linear-gradient(
            -90deg,
            rgb(21, 195, 239),
            rgb(16, 124, 231)
        );

  ${props => !props.isOpen && css`
        background-color: "transparent";
        background-image: none;
  `}
  
`

const VideoContainer = styled.div`
    ${tw`w-full`};
    border-color:  ${props => props.theme.colors.gray};
    border-width: 3px;

    img, video {
      max-width: 100%;
      height: auto;
    }
`

const CourseDetail = () => {

    const [isOpen, toggleOpen] = useCycle(false, true)
    const [files, setFiles] = useState([])
    const [player, setPlayer] = useState(false)
    const publitio = new PublitioAPI('T81z5z5MmLlSWiym7H8q', 'qfkoh8XffvQdfgabPqh88l1aLWDzuRJu')

    useEffect(() => {
        publitio.call('/files/list', 'GET', { offset: '0', limit: '10', folder: 'Lastpass' })
            .then(data => setFiles(data.files))
            .catch(error => console.log(error))

            publitio.call('/files/player/R2i3WehH', 'GET', {
                player: 'bluePlayer',
            }).then((data) => setPlayer(data.source_html) )
              .catch((error) => { console.log(error) })

    }, [])
    


    const lessonsVariants =  {
        show: {
            height: 350,
            opacity: 1,
          transition: {
            type: "spring",
            stiffness: 50,
            restDelta: 2
          }
        },
        hidden: {
            height: 0, 
            opacity: 0,       
            transition: {
            delay: 0.0,
            type: "spring",
            stiffness: 400,
            damping: 40
          }
        }
      };

    return (
        <Layout>
            <Container>
             <div className="container mx-auto grid px-4 sm:px-0 lg:grid-cols-6 gap-8 grid-cols-1">
               <div className="col-span-6 sm:col-span-4">
 
                    <VideoContainer
                        key={`body`}
                        id="___gatsby"
                        dangerouslySetInnerHTML={{ __html: player }}
                        />
                </div>
                <div className="col-span-6 sm:col-span-2 lg:static fixed bottom-0 right-0 z-10 mr-4 mb-4 sm:mr-0 sm:mb-0">
                    <FolderIcon
                        isOpen={isOpen}
                        whileTap={{scale: 0.8}}
                        onClick={() => toggleOpen()} >
                            {isOpen && <FaFolder /> ||  <MdClose size={25}/>}
                    </FolderIcon>
                    <LessonsContainer initial={false}
                                    animate={ isOpen ? "hidden" : "show"}
                                    variants={lessonsVariants}>
                        <div className="flex items-center py-2  font-semibold">
                            <FaFileVideo />
                            <span className="pl-2">Introduction</span>
                        </div>
                        <div className="flex items-center py-2 font-semibold ">
                            <FaFileVideo />
                            <span className="pl-2">Array from dictionary keys in swift</span>
                        </div>
                        <div className="flex items-center py-2 font-semibold">
                            <FaFileVideo />
                            <span className="pl-2">Introduction to swift UI</span>
                        </div>
                        <div className="flex items-center py-2 font-semibold">
                            <FaFileVideo />
                            <span className="pl-2">Introduction</span>
                        </div>
                        <div className="flex items-center py-2 font-semibold">
                            <FaFileVideo />
                            <span className="pl-2">Array from dictionary keys in swift</span>
                        </div>
                        <div className="flex items-center py-2 font-semibold">
                            <FaFileVideo />
                            <span className="pl-2">Introduction to swift UI</span>
                        </div>
                        <div className="flex items-center py-2 font-semibold">
                            <FaFileVideo />
                            <span className="pl-2">Introduction</span>
                        </div>
                        <div className="flex items-center py-2 font-semibold">
                            <FaFileVideo />
                            <span className="pl-2">Array from dictionary keys in swift</span>
                        </div>
                        <div className="flex items-center py-2 font-semibold">
                            <FaFileVideo />
                            <span className="pl-2">Introduction to swift UI</span>
                        </div>
                    </LessonsContainer>
                </div >
            </div>
            </Container>
        </Layout>
    )
}

export default CourseDetail